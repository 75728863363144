import { Link, Outlet, useOutletContext } from 'react-router';
import {
  Footer,
  FooterAboutColette,
  FooterColette,
  FooterContent,
  FooterHelp,
  FooterMeta,
  FooterServices,
} from '~/components/Footer';
import { Header, HeaderNav } from '~/components/Header';
import { Logo } from '~/components/ui/Logo';
import type { ViewerSession } from '~/utils/session.server';

export default function App() {
  const data = useOutletContext<{ viewer: ViewerSession }>();

  return (
    <div>
      <Header>
        <Link to="/" prefetch="intent" aria-label="Club Colette">
          <Logo className="h-6 md:h-8" />
        </Link>

        <HeaderNav viewer={data.viewer} />
      </Header>

      <Outlet />

      <Footer>
        <FooterContent>
          <FooterColette />
          <FooterServices />
          <FooterAboutColette />
          <FooterHelp />
        </FooterContent>

        <FooterMeta />
      </Footer>
    </div>
  );
}
